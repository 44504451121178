:root {
  --bgcolor: hsl(0, 0%, 100%);
  --greenJD: #367c2b;
  --yellowJD: #ffde00;
  --gray: #303133;
  --gray2: #4b484f;
  --dark1: #121015;
  --textcolour: #000000;
  --fontnav: #f1f1f1;
  --fontlight: #f1f1f1;
  --fontdarker: #aaaaaa;
  --border: #973434;

  font-size: 16px !important;

  font-family: Roboto, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

@media (max-width: 3840px) {}

@media (max-width: 1920px) {}

@media (max-width: 1200px) {
  .head-img p {
    top: 40% !important;
    width: 80%;
    max-height: fit-content !important;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16;
  }

  .card {
    width: 80% !important;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

/* ========================= */
/* MOBILE FIXES (<= 768px)   */
/* ========================= */
@media (max-width: 768px) {
  .navbar-collapse {
    max-height: 90vh;
    overflow-y: auto;
  }

  /* Mobile dropdowns should toggle on click */
  .dropdown-menu {
    display: none;
    width: 100%;
    min-width: 18.8rem;
  }

  .dropdown-menu.show {
    display: block;
  }

  /* Nested dropdowns in mobile */
  .dropdown-submenu .dropdown-menu {
    left: 10%;
    top: 100%;
    position: relative;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s;
  }

  .dropdown-submenu .dropdown-menu.show {
    display: block;
    opacity: 1;
    visibility: visible;
  }



  .navbar {
    margin: auto;
    font-size: x-large;
  }
}

/* ========================= */
/* DESKTOP FIXES (> 768px)   */
/* ========================= */
@media (min-width: 769px) {
  /* Default dropdown styling */
  .dropdown-menu {
    display: none;
    position: absolute;
    transform: translateY(-2px);
    color: var(--fontnav);
    background-color: var(--gray2);
    border: 1px solid var(--gray);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s;
  }

  /* Show dropdowns on hover */
  .dropdown:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  /* Fix nested dropdown positioning */
  .dropdown-submenu {
    position: relative;
    color: var(--fontnav);
    background-color: var(--gray2);
    pointer-events: auto;
  }

  .dropdown-submenu .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: auto;
    transition: opacity 0.3s ease, visibility 0.3s;
    margin: auto;
  }

  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  /* Add delay on hover to keep dropdowns open */
  .dropdown:hover > .dropdown-menu,
  .dropdown-submenu:hover > .dropdown-menu {
    transition-delay: 0.2s;  /* Delay before hiding */
  }
}


body {
  
  color: var(--textcolour);
}

.navbar {
  background-color: var(--gray) !important;
  color: var(--fontnav);
  z-index: 10;
  border-bottom: 2px solid var(--greenJD);
}

.modal {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

}

.dropdown-submenu{
  border: none;
}
.dropdown-menu {
  border: none;
  background-color: var(--gray2);
  scrollbar-color: var(--gray);
  font-size: large;
}

.dropdown-item {
  color: var(--fontnav);
}

.addNewsForm {
  height: 100%;
  width: 90%;
  margin: auto;
}

h1,
h2 {
  font-size: 3rem;
  font-weight: 600;
}

h5 {
  font-size: 1.5rem;
  font-weight: 600;
}

p {
  font-size: 1.25rem;
}

.main {
  min-width: 95vw !important;
}

a {
  text-decoration: none !important;
}

.btn {
  margin: auto;
  display: flex;
  margin-top: 1em;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--gray2);
  color: white;
  border-color: var(--greenJD);
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: var(--greenJD);
  border-color: var(--dark1);
}

.image-container {
  display: inline-flex;
  gap: 0;
  width: 100%;
}

.image-container img {
  width: 100%;
}

.post {
  padding: 2rem !important;
  color: var(--dark1) !important;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.7);
}

.head-img {
  position: relative !important;
  height: 400px !important;
}

.head-img img {
  width: 100% !important;
  max-height: fit-content;
}

.head-img p,
.head-img h2 {
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 10px !important;
  font-weight: bold;
  text-align: center !important;
}

.head-img p {
  top: 50%;
  width: 80% !important;
}

.head-img h2 {
  top: 20% !important;
  width: 40% !important;
}


.centered {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.cutout {
  display: block !important;
  background-color: var(--gray2) !important;
  width: 400px !important;
  height: 6em !important;
  border-radius: 1rem !important;
  margin: 0.5rem 0.5rem 1rem 0.5rem !important;
}



.fixed-container {
  position: absolute !important;
  padding-top: 1rem;
  left: 5vw;
  width: 90vw;
  background-color: var(--bgcolor);
}

.full-width-green,
.full-width-grey {
  background-color: var(--dark1);
  color: var(--gray2);
  display: flex;
  font-weight: 650;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.full-width-green {
  height: 125px;
  font-size: 4rem;
}

.full-width-grey {
  height: 80px;
  width: 90%;
}

.top-rounded {
  border-radius: 0.5rem 0.5rem 0 0;
}

.contact {
  margin: 1rem;
  padding: 1rem;
}

.title {
  font-size: 7.5rem;
  text-shadow: 0.03em 0.03em black;
}

.header-image {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 25rem;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 1.5rem;
}

.card-img-overlay {
  position: absolute;
  top: -120px !important;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  pointer-events: none;
}

.overlay-title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  z-index: 2;
  pointer-events: auto;
}

.card-img-top {
  position: relative;
  z-index: 0;
  min-height: 150px;
}

/* Styling for the CAROUSEL COMPNENET */
.product-slider {
  padding: 20px 0;
}

.product-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card {
  border: none;
  transition: transform 0.3s ease-in-out;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image-wrapper {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.product-title {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 10px 0;
  background: #f8f8f8;
  margin: 0;
}



.category-carousel {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;
  cursor: grab;
}

.category-carousel:active {
  cursor: grabbing;
}

.category-item {
  flex: 0 0 auto;
  margin: 1rem;
  min-width: 12.5rem;
  text-align: center;
}

.category-item img {
  width: 100%;
  min-height: 9.5rem;
  object-fit: cover;
  border-radius: 0.3rem;
}

.category-item h5 {
  margin-top: 0.7rem;
  font-size: 1.25rem;
  color: gray;
  font-weight: 700;
}

.btn-news {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1;
  background-color: #443c4f82;
  border: none;
}

/* Breadcrumbs */
.breadcrumb {
  background-color: var(--gray2) !important;
  padding: 0.5em 0.5em !important;
  font-weight: 700 !important;
  border-bottom: 2px solid var(--yellowJD) !important;
}

.breadcrumb-item,
.breadcrumb-item a {
  font-size: 1rem !important;
  line-height: 1.6 !important;
  padding: 0 0.5rem !important;
  color: var(--fontdarker);
}

.breadcrumb-item:hover {
  color: var(--greenJD);
  font-weight: bolder;
}

.breadcrumb-item.active {
  color: var(--greenJD) ip !important;
}

.breadcrumb-item.active:hover {
  cursor: default !important;
  font-weight: 650 !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: " > ";
  padding: 0 0.25rem;
}

.map-container iframe {
  width: 100%;
  height: 400px;
  border: 0;
}

.supplier-ribbon {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
}

.supplier {
  flex: 0 0 auto;
  margin: 0 10px;
  text-align: center;
}

.supplier img {
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Suppliers Falcon Rociv etc */

.border-top-falcon{
  border-top: 15px solid #2BB04A;
  border-radius: 5px;
}

/* Bootstrap - Images */
.img-fluid {
  width: 100% !important;
  height: 20rem !important;
  object-fit: cover;
  object-position: center;
  display: block;
}

/* Keen Slider Css */

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 50px; */
  /* color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh; */
}

.number-slide1 {
  background: var(--greenJD);
  font-weight: bolder;
  border: 1px #aaaaaa solid;
}

.number-slide2 {
  background: rgb(255, 75, 64);
  background: linear-gradient(
    128deg,
    rgba(255, 154, 63, 1) 0%,
    rgba(255, 75, 64, 1) 100%
  );
}

.number-slide3 {
  background: rgb(182, 255, 64);
  background: linear-gradient(
    128deg,
    rgba(182, 255, 64, 1) 0%,
    rgba(63, 255, 71, 1) 100%
  );
  background: linear-gradient(
    128deg,
    rgba(189, 255, 83, 1) 0%,
    rgba(43, 250, 82, 1) 100%
  );
}

.number-slide4 {
  background: rgb(64, 255, 242);
  background: linear-gradient(
    128deg,
    rgba(64, 255, 242, 1) 0%,
    rgba(63, 188, 255, 1) 100%
  );
}

.number-slide5 {
  background: rgb(255, 64, 156);
  background: linear-gradient(
    128deg,
    rgba(255, 64, 156, 1) 0%,
    rgba(255, 63, 63, 1) 100%
  );
}
.number-slide6 {
  background: rgb(64, 76, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 76, 255, 1) 0%,
    rgba(174, 63, 255, 1) 100%
  );
}
